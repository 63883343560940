import React, {Component} from 'react';
import Header from "../containers/Header";
export default class TopMid extends Component {
    render(){
        return (
            <div className='border-right border-left padding-top-md'>
                <Header/>
                <div className='l-apple-box-multiple'></div>
                <div className='l-apple-box-multiple'></div>
                <div className='l-apple-box-multiple'></div>
                <div className='l-apple-box-multiple'></div>
                <div className='l-apple-box--half'></div>
                <div className='border-md'></div>
                <div className='l-apple-box-multiple'></div>
                <div className='l-apple-box-multiple'></div>

                <div classname='centering'>
                    <div className='text padding-left-right-lg t-21 line-h-md'>
                    Hyemi Song is a researcher and a designer interested in topics related to ‘data, communication, and human ’ in complex systems. Her academic research areas include information visualization, human-computer interaction, natural language, and immersive analytics.
                    She studies the interplay between Natural Language and Data Visualization in immersive environments within generative
                    AI-powered immersive analytics under the supervison of <a href='https://www.cs.umd.edu/~varshney/index.html' target="_blank" class='webintext'>Dr. Amitabh Varshney.</a>
                    <p></p>
                    Currently, Hyemi is pursuing a Ph.D. in
                    &nbsp;<a href='https://www.cs.umd.edu/' target="_blank" class='webintext'>
                    Computer Science at the University of Maryland, College Park.
                    </a>&nbsp;
                    Previously, she was a research fellow, as a Data Visualization Specialist at
                    &nbsp;<a href='https://senseable.mit.edu/' target="_blank" class='webintext'>
                    MIT Senseable City Lab.
                    </a>&nbsp;
                    Prior to this, she received an MFA from
                    &nbsp;<a href='https://www.risd.edu/' target="_blank" class='webintext'>
                    Rhode Island School of Design.
                    </a>&nbsp;
                    <p></p>
                    As a data visualization and UX/UI designer, Hyemi has worked with several international companies and institutions, such as Microsoft, Naver, Samsung, RISD, MIT and more. There, she participated in diverse product designs and developments for in the areas of AI and Machine Learning, Social Media, Mobile Operating System and Application, Search Engine, and Data Visualization.
                    <p></p>
                    Hyemi’s personal and commercial works have been featured in international awards, media, and exhibitions. These include Information is Beautiful Awards, IEEE Arts Program, Fast Company, Monthly Design Magazine, iF Design Awards, The Guardian, IdN, American Graphic Design Awards (GDUSA), Data Vis Today, Raw Data: Infographic Designers' Sketchbooks, Visualising Data, Visual Loop, Adobe Design Achievement Awards, Good Design Award, Seoul Design Olympiad, RISD Museum, De Young Museum, Internet Festival at Pisa, Hebei Design Center, MOOD Museum of Design among others.
                    <p></p>
                    At the 
                    &nbsp;<a href='https://bohyemian.com/' target="_blank" class='webintext'>
                    Bohyemian Lab,
                    </a>&nbsp;
                    Hyemi conducts design research projects focusing on data-driven self-expression through walking, reading, and more. 
                    Previous art & design projects are also archived on this website
                    &nbsp;<a href='https://bohyemian.com/' target="_blank" class='webintext'>
                    https://bohyemian.com/.
                    </a>
                    <p></p>
                    She helps competitions and conferences as a jouror, reviewer, a program comittee: the IEEEVIS (2023~Present), the DIS (2024), the PacificVis (2022~Present), the IEEEVis Arts Program (2018~Present), the A'Design Award & Competition (2017~2022), and the 2018 Apexart exhibition.
                    
                    <div className='l-apple-box-multiple'></div>
                    <div className='border-sm'></div>
                    <div className='l-apple-box-multiple'></div>
                    {/* [2024] One first-authored paper is published as a preprint.<br></br>
                    [2024] One co-authored paper is accepted.<br></br> */}
                    [2024] Earned a CS Master's degree.<br></br>
                    [2024] Paper Reviewer: DIS24, VIS24, CHI25, Pacific Vis25. <br></br>
                    [2024] Program Committee: IEEEVIS Art Program (primary reviewer).<br></br>
                    [2024] Recognized as best reviewer (DIS24).<br></br>
                    [2024] Awarded ACM Membership for DIS24 review contribution.<br></br>               
                    [2023] Awarded Diversity and Inclusion Scholarship (VIS23). <br></br>
                    [2023] Accepted for Exhibition (<a href='https://twitter.com/visapnet/status/1715548542075334895/photo/1' target="_blank" class='webintext'>Solar System</a>), IEEEVIS Art Program. <br></br>
                    {/* [2023] One co-authored paper is published as a preprint.<br></br> */}
                    [2023] Paper Reviewer: Full paper (VIS23). <br></br>
                    [2023] Program Committee: IEEEVIS Art Program. <br></br>
                    [2023] Invited Talk at Ohio State University (declined). <br></br>
                    [2023] Juror, PacificVis. <br></br>
                    </div>
                    <p></p>
                </div>
                <div className='l-apple-box-multiple'></div>
                <div className='border-sm'></div>
                <div className='l-apple-box-multiple'></div>
                <div className='l-apple-box-multiple'></div>
                <div className='l-apple-box-multiple'></div>
                <div className='l-apple-box-multiple'></div>
            </div>
        )
    }
}